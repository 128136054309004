import React, { useRef } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { useNavigate } from 'react-router-dom';
import Cards from './Cards'; // Import the Cards component

function HeroSection() {
  const navigate = useNavigate();
  const servicesRef = useRef(null);

  const handleViewServicesClick = () => {
    if (servicesRef.current) {
      const topOffset = servicesRef.current.offsetTop - 20;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleAboutUsClick = () => {
    navigate('/about-us');
  };

  return (
    <div>
      <div className='hero-container'>
        <video src='/videos/video-2.mp4' autoPlay loop muted />
        <h1>The Right Solution. For You.</h1>
        <h2>Providing software consulting solutions for our clients since 2021.</h2>
        <div className='hero-btns'>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            onClick={handleAboutUsClick}
          >
            About Us <i className="fas fa-info-circle" />
          </Button>
          
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            onClick={handleViewServicesClick}
          >
            View Services <i className='fa fa-chevron-circle-right' />
          </Button>
        </div>
      </div>

      {/* Render the Cards component and pass the servicesRef */}
      <Cards servicesRef={servicesRef} />
    </div>
  );
}

export default HeroSection;
